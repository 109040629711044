import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'i18n';
import { routes } from 'utils/routing';

import { Event } from 'api/general/models';
import EventsSection from 'components/common/EventsSection';
import RenderComponent from '../common/RenderComponent';

export interface UpcomingAndPastEventsProps {
  organizationId: number;
  upcomingEvents: Event[];
  pastEvents: Event[];
  onToggleFavoriteEvent: () => void;
  isPastEventFetching: boolean;
  isUpcomingEventFetching: boolean;
}

const UpcomingAndPastEvents: React.FC<React.PropsWithChildren<UpcomingAndPastEventsProps>> = ({
  organizationId,
  upcomingEvents,
  pastEvents,
  onToggleFavoriteEvent,
  isPastEventFetching,
  isUpcomingEventFetching,
}) => {
  const [t] = useTranslation('public');

  const hasUpcomingEvents = upcomingEvents.length > 0;
  const hasPastEvents = pastEvents.length > 0;

  return (
    <Grid container spacing={6} justifyContent="center">
      <RenderComponent
        isLoading={isUpcomingEventFetching}
        isFetched={hasUpcomingEvents}
        renderContent={() => (
          <Grid item xs={12}>
            <EventsSection
              title={t<string>('organization.upcomingEvents')}
              events={upcomingEvents}
              itemsPerPage={100}
              onToggleFavoriteEvent={onToggleFavoriteEvent}
              {...routes.upcomingEvents(organizationId)}
            />
          </Grid>
        )}
      />
      <RenderComponent
        isLoading={isPastEventFetching}
        isFetched={hasPastEvents}
        renderContent={() => (
          <Grid item xs={12}>
            <EventsSection
              title={t<string>('organization.pastEvents')}
              events={pastEvents}
              itemsPerPage={3}
              onToggleFavoriteEvent={onToggleFavoriteEvent}
              {...routes.pastEvents(organizationId)}
            />
          </Grid>
        )}
      />
    </Grid>
  );
};

export default memo(UpcomingAndPastEvents);
